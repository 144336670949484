import { HttpResponse } from '@angular/common/http';
import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatRadioButton } from '@angular/material/radio';
import { Router } from '@angular/router';
import { ApplicationApi } from '@app/acquisition/application/application.api';
import {
  ApplicationData,
  ApplicationStates,
} from '@app/acquisition/application/application.models';
import { ApplicationDataService } from '@app/acquisition/application/application.service';
import { LogRocketService } from '@app/acquisition/services/log-rocket/log-rocket.service';
import { NeuroIdService } from '@app/acquisition/services/neuro-id/neuro-id.service';
import {
  SessionStorageKeys,
  SessionStorageObjects,
  SessionStorageService,
} from '@app/acquisition/services/session-storage-service/session-storage.service';
import { UserInactivityStatusService } from '@app/acquisition/shared-module/inactivity-module/user-inactivity.service';
import { SpinnerModalComponent } from '@app/acquisition/shared-module/spinner-modal/spinner-modal.component';
import { Tealium, TealiumVariables } from '@app/tealium/tealium.service';
import { ValidationMessagesError } from '@app/validation/validation-messages/validation-error';
import * as moment from 'moment';
import {
  AppendDeviceRequestObject,
  DeviceApiService,
} from '../../services/append-device.api';
import {
  ApplicantResumeModalData,
  SendCodeRequestModel,
  VerifyCodeRequestModel,
  VerifyCodeResponseModel,
} from '../../services/otp-resume-app/otp.models';
import {
  OtpService,
  StartOptions,
} from '../../services/otp-resume-app/otp.service';
import {
  NextPath,
  ResumeApplicationLogicService,
} from '../../services/otp-resume-app/resume-application-logic.service';
import {
  ResumeApplicationInfoFormGroup,
  ResumeApplicationVerificationInfoFormGroup,
} from './resume-application-modal.form';

@Component({
  selector: 'rise-resume-application-modal',
  templateUrl: './resume-application-modal.component.html',
  styleUrls: ['./resume-application-modal.component.scss'],
})
export class ResumeApplicationModalComponent implements OnInit {
  public page: String;
  public phoneNumberLast4: String;
  public socialSecurity: String;
  public contactTypeForm: ResumeApplicationInfoFormGroup;
  public verificationForm: ResumeApplicationVerificationInfoFormGroup;
  private dialogSpinner: MatDialogRef<SpinnerModalComponent>;

  @ViewChild('callRadioButton')
  private callRadioButton: MatRadioButton;
  @ViewChild('textRadioButton')
  private textRadioButton: MatRadioButton;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ApplicantResumeModalData,
    public dialogRef: MatDialogRef<ResumeApplicationModalComponent>,
    public ngZone: NgZone,
    private userAppStarted: UserInactivityStatusService,
    private otpResumeApplicationService: OtpService,
    private dialog: MatDialog,
    private neuroIdService: NeuroIdService,
    private applicationApi: ApplicationApi,
    private applicationDataService: ApplicationDataService,
    private sessionStorageService: SessionStorageService,
    private resumeApplicationLogicService: ResumeApplicationLogicService,
    private router: Router,
    private deviceApiService: DeviceApiService,
    private logRocketService: LogRocketService,
    private tealium: Tealium
  ) {
    this.contactTypeForm = new ResumeApplicationInfoFormGroup();
    this.verificationForm = new ResumeApplicationVerificationInfoFormGroup();
    this.phoneNumberLast4 = data.phoneNumberLast4;
  }

  public ngOnInit(): void {
    this.page =
      this.data.startOptions === StartOptions.ResumeOnly
        ? 'WelcomeWithoutOptions'
        : 'WelcomeWithOptions';
  }

  public closeModal(): void {
    this.dialogRef.close({ resumeApplication: false });
  }

  public showSendCode(): void {
    this.page = 'SendCode';
    this.tealium.popUpEvent('Verify-Identity-OTP1');
  }

  public goBack(): void {
    this.page = 'SendCode';
    this.verificationForm.reset();
    setTimeout(() => {
      if (this.contactTypeForm.value.sendCodeMethod === 'Sms') {
        this.textRadioButton.checked = true;
      } else {
        this.callRadioButton.checked = true;
      }
    }, 100);
  }

  public radioButtonChange(sendCodeMethod: string): void {
    this.contactTypeForm.get('sendCodeMethod').setValue(sendCodeMethod);

    if (sendCodeMethod === 'Sms') {
      this.textRadioButton.checked = true;
    } else if (sendCodeMethod === 'Call') {
      this.callRadioButton.checked = true;
    }
  }

  public sendVerificationCode(): void {
    this.contactTypeForm.showValidationErrors();
    if (!this.contactTypeForm.valid) {
      return;
    }
    this.dialogSpinner = this.dialog.open(SpinnerModalComponent, {
      panelClass: 'rise-saving-modal',
      disableClose: true,
      closeOnNavigation: false,
    });
    this.tealium.popUpEvent('Verify-Identity-OTP1-Popup-Processing');
    const otpSendCodeInfo: SendCodeRequestModel = {
      applicationId: this.data.applicationId,
      channel: this.contactTypeForm.value.sendCodeMethod,
    };

    this.otpResumeApplicationService.sendCode(otpSendCodeInfo).subscribe(
      () => {
        this.moveToVerifyScreen();
      },
      () => {
        this.moveToVerifyScreen();
      }
    );
  }

  public verificationSubmit(): void {
    this.verificationForm.showValidationErrors();

    if (!this.verificationForm.valid) {
      return;
    }
    this.dialogSpinner = this.dialog.open(SpinnerModalComponent, {
      panelClass: 'rise-saving-modal',
      disableClose: true,
      closeOnNavigation: false,
    });
    this.tealium.popUpEvent('Verify-Identity-OTP2-Popup-Processing');
    const otpVerifyCode: VerifyCodeRequestModel = {
      applicationId: this.data.applicationId,
      otp: this.verificationForm.value.verificationCode,
    };

    this.otpResumeApplicationService.verifyCode(otpVerifyCode).subscribe(
      (response: HttpResponse<VerifyCodeResponseModel>) => {
        if (response.status === 200) {
          this.resumeApplication(response.body.token);
        }
      },
      (error: HttpResponse<any>) => {
        this.verificationForm
          .get('verificationCode')
          .setErrors(
            new ValidationMessagesError(
              'verificationCode',
              null,
              'Incorrect verification code. Please re-enter.'
            )
          );
        this.tealium.notificationEvent(
          'Verify-Identity-OTP2-Popup-VerificationCode-Error'
        );
        this.dialogSpinner.close();
      }
    );
  }

  private resumeApplication(token: string): void {
    this.sessionStorageService.setSessionStorageItem(
      SessionStorageKeys.authorizationToken,
      token
    );
    this.applicationApi.get().subscribe((applicationData: ApplicationData) => {
      this.logRocketService.addSearchableFields();

      this.applicationDataService.mergeArray({
        ...applicationData,
      });

      const appStateAllowsToAppend =
        applicationData.state !== ApplicationStates.Booked &&
        applicationData.state !== ApplicationStates.Accepted;

      if (appStateAllowsToAppend) {
        this.deviceApiService
          .append(this.getAppendDevicePayload())
          .subscribe((response: HttpResponse<any>) => {
            if (response.status === 204) {
              this.resolveNavigate(applicationData);
            }
          });
      } else {
        this.resolveNavigate(applicationData);
      }
    });
  }

  private resolveNavigate(applicationData: ApplicationData): void {
    this.userAppStarted.setUserApplicationStatus(true);
    this.dialogSpinner.close();
    this.dialogRef.close({ resumeApplication: true });
    this.resumeTealium(applicationData);

    const nextPath: NextPath =
      this.resumeApplicationLogicService.getNextPath(applicationData);
    const nextPathURL = nextPath.url;
    const navigationExtras = nextPath.navigationExtras || {};
    this.ngZone.run(() =>
      this.router.navigate([nextPathURL], navigationExtras)
    );
  }

  private moveToVerifyScreen(): void {
    this.page = 'VerifyCode';
    this.dialogSpinner.close();
    this.tealium.popUpEvent('Verify-Identity-OTP2');
  }

  private getAppendDevicePayload(): AppendDeviceRequestObject {
    const urlData = this.sessionStorageService.getSessionStorageObject(
      SessionStorageObjects.queryParameters
    );

    const payload: AppendDeviceRequestObject = {
      neuroID: this.neuroIdService.getNeuroSessionId(),
    };

    if (urlData) {
      if (urlData.queryString) {
        payload.queryString = urlData.queryString;
      }

      if (urlData.params) {
        if (urlData.params.gcid) {
          payload.GCID = urlData.params.gcid;
        }

        if (urlData.params.referralUrl) {
          payload.referrerUrl = urlData.params.referralUrl;
        }
      }
    }

    return payload;
  }

  private resumeTealium(applicationData: ApplicationData): void {
    const { applicant } = applicationData.form;

    const tealiumVariables: TealiumVariables = {
      customer_email: applicant.emails[0].address,
      first_name: applicant.identity.firstName,
      last_name: applicant.identity.lastName,
      application_id: applicationData.id,
      sequence_id: applicationData.sequenceApplicationId,
      state: applicant.residences[0].address.stateCode,
      application_start_date: applicationData.createdAt,
      resumed_app: true,
      neuroId: this.neuroIdService.getNeuroSessionId(),
    };

    if (this.hasProp(applicationData, 'form.requestedAmount')) {
      tealiumVariables.loan_requested_amount =
        applicationData.form.requestedAmount;
    }

    if (this.hasProp(applicationData, 'form.enrollmentCode')) {
      tealiumVariables.enrollment_code = applicationData.form.enrollmentCode;
    }

    if (
      this.hasProp(
        applicationData,
        'underwriting.steps.0.disposition.offer.line'
      )
    ) {
      tealiumVariables.pre_qual_amount =
        applicationData.underwriting.steps[0].disposition.offer.line;
    }

    if (
      this.hasProp(
        applicationData,
        'underwriting.steps.1.disposition.offer.line'
      )
    ) {
      tealiumVariables.loan_amount = applicationData.form.requestedAmount;
      tealiumVariables.loan_apr =
        applicationData.underwriting.steps[1].disposition.offer.apr;
      tealiumVariables.approved_amount =
        applicationData.underwriting.steps[1].disposition.offer.line;
      tealiumVariables.loan_date =
        applicationData.underwriting.decisions[1].requestedAt;
      if (applicant.income.sources[0].confirmedPayDates) {
        tealiumVariables.loan_first_payment_date = moment
          .utc(applicant.income.sources[0].confirmedPayDates[0].date)
          .format();
      }
    }

    if (applicationData.signedDate) {
      tealiumVariables.application_complete_date = applicationData.signedDate;
    }

    this.tealium.setTealiumVariables(tealiumVariables);
  }

  private hasProp(object: any, property: string): boolean {
    let objectAcum = object;
    const parts = property.split('.');
    const l = parts.length;
    for (let i = 0; i < l; i++) {
      const part = parts[i];
      if (
        objectAcum !== null &&
        typeof objectAcum === 'object' &&
        part in objectAcum
      ) {
        objectAcum = objectAcum[part];
      } else {
        return false;
      }
    }
    return true;
  }
}
