/* istanbul ignore file */
import { Injectable } from '@angular/core';
import { OtpService } from '../../services/otp-resume-app/otp.service';
import {
  GettingStarted,
  GettingStartedFormGroup,
} from '../form/getting-started.form';

@Injectable()
export class GettingStartedDebugService {
  public isReCaptchaEnabled = true;

  constructor(
    private form: GettingStartedFormGroup,
    private otpService: OtpService
  ) {}

  public simulateData(): void {
    const randomSocialSecurityNumber = this.getRandomSocialSecurityNumber();
    const randomCharacterString = this.getRandomLowerCaseString(5);
    const value: GettingStarted = {
      firstName: `TEST${randomCharacterString}`,
      lastName: `AUTO${randomCharacterString}`,
      zipCode: '38768',
      ssn: randomSocialSecurityNumber,
      email: `test_${randomSocialSecurityNumber}@risecash.net`,
      mobilePhoneNumber: '6829369422',
      directMailCode: 'MYCODE',
    };

    this.form.get('ssn').reset();
    this.form.patchValue(value);
  }

  public toggleReCatpcha(): void {
    this.isReCaptchaEnabled = !this.isReCaptchaEnabled;
  }

  public setEmailOnlyTimeout(): void {
    this.otpService.setTimeoutToOneMinute();
  }

  private getRandomSocialSecurityNumber(): string {
    const prefix = Math.floor(900 + Math.random() * 70).toString();
    const subset = Math.floor(1000 + Math.random() * 9000).toString();
    return `${prefix}${subset}55`;
  }

  private getRandomLowerCaseString(length: number): string {
    const aCode = 'a'.charCodeAt(0);
    const zCode = 'z'.charCodeAt(0);
    let randomString = '';
    for (let i = 0; i < length; i++) {
      const randomCharacterCode =
        Math.floor(Math.random() * (zCode - aCode + 1)) + aCode;
      randomString += String.fromCharCode(randomCharacterCode);
    }
    return randomString;
  }
}
