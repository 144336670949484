import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Environment } from '@environment/environment.module';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApplicationData, ApplicationForm } from './application.models';

export interface ApplicationCreateResponse {
  token: string;
}

export interface CreateApplicationRequest {
  form: ApplicationForm;
  applicationFlow: string;
  brand: string;
  partnerLeadId?: string;
  partner?: PartnerOfferIds;
  customerId?: string;
  customerIdSignature?: string;
  recaptchaToken?: string;
}

export interface CreateFormerApplicationRequest
  extends CreateApplicationRequest {
  brandCustomerId: string;
}

export interface PartnerOfferIds {
  leadId: string;
  offerId: string;
}

@Injectable()
export class ApplicationApi {
  constructor(
    protected environment: Environment,
    protected http: HttpClient,
    protected router: Router,
    protected dialog: MatDialog
  ) {}

  public create(
    request: CreateApplicationRequest
  ): Observable<ApplicationCreateResponse> {
    return this.http
      .post<ApplicationCreateResponse>(
        `${this.environment.brandApi.url}/api/v1/application`,
        request,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        }
      )
      .pipe(
        catchError((error: any) => {
          const httpError = error as HttpErrorResponse;
          if (httpError.status !== 429) {
            if (httpError.error.message.includes('is not Serviced')) {
              throw error;
            } else {
              this.navigateToErrorPage();
            }
          }
          return throwError(error);
        })
      );
  }

  public append(data: ApplicationForm): Observable<any> {
    return this.http
      .patch<HttpResponse<any>>(
        `${this.environment.brandApi.url}/api/v1/application`,
        data,
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
        }
      )
      .pipe(
        catchError((error: any) => {
          const httpError = error as HttpErrorResponse;
          if (httpError.status !== 400 && httpError.status !== 406) {
            // if it's not a bank error
            this.navigateToErrorPage();
          }
          return throwError(error);
        })
      );
  }

  public replace(data: any): Observable<any> {
    return this.http
      .patch<HttpResponse<any>>(
        `${this.environment.brandApi.url}/api/v1/application/replace`,
        data,
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
        }
      )
      .pipe(
        catchError((error: any) => {
          const httpError = error as HttpErrorResponse;
          if (httpError.status !== 400 && httpError.status !== 406) {
            // if it's not a bank error
            this.navigateToErrorPage();
          }
          return throwError(error);
        })
      );
  }

  public processformerbankdata(data: any): Observable<any> {
    return this.http
      .patch<HttpResponse<any>>(
        `${this.environment.brandApi.url}/api/v1/application/processformerbankdata`,
        data,
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          observe: 'response',
        }
      )
      .pipe(
        catchError((error: any) => {
          const httpError = error as HttpErrorResponse;
          if (httpError.status !== 400 && httpError.status !== 406) {
            // if it's not a bank error
            this.navigateToErrorPage();
          }
          return throwError(error);
        })
      );
  }

  public get(): Observable<ApplicationData> {
    return this.http
      .get<ApplicationData>(
        `${this.environment.brandApi.url}/api/v1/application`
      )
      .pipe(
        catchError((error: any) => {
          const httpError = error as HttpErrorResponse;
          if (httpError.status === 401) {
            // if unauthorized response, no auth token
            this.router.navigate(['/apply/getting-started']);
          } else {
            // any other error
            this.navigateToErrorPage();
          }
          return throwError(error);
        })
      );
  }

  protected navigateToErrorPage(): void {
    this.dialog.closeAll();
    this.router.navigate(['../error']);
  }
}
