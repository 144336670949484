import { Injectable } from '@angular/core';

export enum SessionStorageKeys {
  authorizationToken = 'authorizationToken',
  showcookieMessage = 'showcookieMessage',
  plaidLinkToken = 'plaidLinkToken',
  oauthStateId = 'oauthStateId',
  IsCoBrowseDisabled = 'IsCoBrowseDisabled',
}

export enum SessionStorageObjects {
  bankAccountsInfo = 'bankAccountsInfo',
  selectedPaymentOption = 'selectedPaymentOption',
  queryParameters = 'queryParameters',
  tealiumVariables = 'tealiumvariables',
  emailOnlyAttempts = 'emailOnlyAttempts',
  stateOptions = 'stateOptions',
  originationFeeConfig = 'originationFeeConfig',
  partnerOfferInfo = 'partnerOfferInfo'
}

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor() {}

  public setSessionStorageItem(key: SessionStorageKeys, value: any): void {
    sessionStorage.setItem(key, value);
  }

  public getSessionStorageItem(key: SessionStorageKeys): any {
    return sessionStorage.getItem(key);
  }

  public deleteSessionStorageItem(key: SessionStorageKeys): void {
    sessionStorage.removeItem(key);
  }

  public setSessionStorageObject(key: SessionStorageObjects, value: any): void {
    const jsonObj = JSON.stringify(value);
    sessionStorage.setItem(key, jsonObj);
  }

  public getSessionStorageObject(key: SessionStorageObjects): any {
    const sessionString = sessionStorage.getItem(key);
    return JSON.parse(sessionString);
  }

  public deleteSessionStorageObject(key: SessionStorageObjects): void {
    sessionStorage.removeItem(key);
  }
  public clearAllSessionStorage(): void {
    sessionStorage.clear();
  }

  public clearAllSessionStorageButPartners(): void {
    const partners = this.getSessionStorageObject(SessionStorageObjects.partnerOfferInfo);
    sessionStorage.clear();
    if(partners)
    {
      this.setSessionStorageObject(SessionStorageObjects.partnerOfferInfo, partners);
    }
  }
}
