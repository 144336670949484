import { TrackedRequests, UnderwritingModel } from './underwriting.model';

export interface ApplicantIdentityModel {
  socialSecurityNumber?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
}

export interface AplicantAddressModel {
  line1?: string;
  line2?: string;
  city?: string;
  stateCode?: string;
  zipCode?: string;
}

export interface ApplicantResidenceModel {
  key?: string;
  type?: string;
  address?: AplicantAddressModel;
  monthlyAmount?: number;
}

export interface AgreementConsentModel {
  key?: string;
  date?: string;
  url?: string;
}

export interface ApplicantBankAccountModel {
  key?: string;
  institution?: string;
  type?: string;
  accountNumber?: string;
  routingNumber?: string;
  source?: string;
  accountNumberLast4?: string;
}

export interface ApplicantPhoneModel {
  key?: string;
  number?: string;
  type?: string;
}

export interface ApplicantEmailModel {
  key?: string;
  address?: string;
  type?: string;
  status?: string;
}

export interface ApplicantEmploymentModel {
  key?: string;
  employer?: string;
  incomeSourceKey?: string;
  phone?: {
    number?: string;
    extension?: string;
  };
}

export interface ApplicantIncomeModel {
  grossAnnualAmount?: number;
  grossMonthlyAmount?: number;
  monthlyHousingCost?: {
    amount?: number;
  };
  sources?: ApplicantIncomeSourcesModel[];
}

export interface ApplicantIncomeSourcesModel {
  key?: string;
  type?: string;
  frequency?: string;
  daysOfMonth?: {
    first?: number;
    second?: number;
  };
  netAmount?: number;
  nextPayDate?: string;
  confirmedPayDates?: NextThreePayDates[];
}
export interface MarriedSpouseModel {
  status: MaritalStatusModel;
  spouseFirstName?: string;
  spouseLastName?: string;
  spouseEmailAddress?: string;
}
export enum MaritalStatusModel {
  Married = 'Married',
  Unmarried = 'Single',
}
export interface NextThreePayDates {
  key: string;
  date: string;
  paycheckAmount: number;
}

export enum UnderwritingDecisionEnum {
  Decline = 'Decline',
  Offer = 'Offer',
  MoreInfo = 'MoreInformation',
  CounterOffer = 'CounterOffer',
  NoOffer = 'NoOffer',
  Continue = 'Continue',
  LenderChange = 'LenderChange',
}

export enum ApplicationStates {
  Approved = 'Approved',
  InReview = 'InReview',
  InformationNeeded = 'InformationNeeded',
  Declined = 'Declined',
  Booked = 'Booked',
  Accepted = 'Accepted',
  InProgress = 'InProgress',
  Cancelled = 'Cancelled',
  PendingDecision = 'PendingDecision',
}
export enum ApplicationFlows {
  PartnerPrequal = 'PartnerPrequal',
  Former = 'Former',
  Refi = 'Refinance',
  OrganicPrequal = 'OrganicPrequal',
  PartnerPrepop = 'PartnerPrepop',
}
export interface Disclosure {
  key: string;
  consentGiven: boolean;
}

export interface ApplicationData {
  id?: string;
  sequenceApplicationId?: number;
  createdAt?: string;
  expiresAt?: string;
  brand?: string;
  product?: ProductModel;
  form?: ApplicationForm;
  offer?: UnderwritingOfferModel;
  state?: ApplicationStates;
  applicationFlow?: ApplicationFlows;
  mostRecentDecision?: string;
  underwriting?: UnderwritingModel;
  trackedRequests?: TrackedRequests[];
  signedDate?: string;
  submitDate?: string;
  acceptedTerms?: AcceptedTerms;
  partner?: PartnerOfferIds;
}

export interface PartnerOfferIds {
  leadId: string;
  offerId: string;
}
export interface AcceptedTerms {
  documentResourceUrl?: string;
  terms?: Terms;
}

export interface Terms {
  line?: number;
  numberOfPayments?: number;
  firstPaymentAt?: string;
  effectiveAt?: string;
  interestRate?: number;
  tilaApr?: number;
  feeDailyRate?: number;
  disbursementDate?: string;
  paymentAmount?: number;
}

export interface ProductModel {
  lenderCode?: string;
  id?: number;
  productRollupId?: number;
  stateLine?: StateLineModel;
}
export interface StateLineModel {
  minAmount?: number;
  maxAmount?: number;
}

export interface UnderwritingOfferModel {
  line?: number;
  apr?: number;
  expireAt?: string;
}

export enum FundMethodType {
  Paper = 'Paper',
  Electronic = 'Electronic',
}

export interface ApplicationForm {
  applicationId?: string;
  enrollmentCode?: string;
  disclosures?: Disclosure[];
  requestedAmount?: number;
  fundMethod?: FundMethodType;
  paymentMethod?: FundMethodType;
  agreementConsent?: AgreementConsentModel[];
  applicant?: {
    marital?: MarriedSpouseModel;
    recentlyMoved?: boolean;
    identity?: ApplicantIdentityModel;
    residences?: ApplicantResidenceModel[];
    bank?: {
      provider?: {
        token?: string;
        source?: string;
      };
      draftFromKey?: string;
      connectionResourceUrl?: string;
      accounts?: ApplicantBankAccountModel[];
    };
    phones?: ApplicantPhoneModel[];
    emails?: ApplicantEmailModel[];
    employments?: ApplicantEmploymentModel[];
    income?: ApplicantIncomeModel;
  };
}
