import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { SessionStorageService } from '@app/acquisition/services/session-storage-service/session-storage.service';
import { Tealium } from '@app/tealium/tealium.service';
import { SessionStorageObjects } from '../acquisition/services/session-storage-service/session-storage.service';

@Injectable()
export class TealiumVariablesResolver implements Resolve<void> {
  constructor(
    private sessionStorage: SessionStorageService,
    private tealium: Tealium
  ) {}
  public resolve(): void {
    const tealiumSessionStorageVariables =
      this.sessionStorage.getSessionStorageObject(
        SessionStorageObjects.tealiumVariables
      );

    this.tealium.setTealiumData(tealiumSessionStorageVariables);
  }
}
