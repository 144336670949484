import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Tealium, TealiumData, TealiumEvent } from '../tealium.service';

@Directive({
  selector: '[tealiumInputFocus]',
})
export class TealiumInputFocusDirective {
  @Input('tealiumInputFocus') public tealiumCategory: string;

  private hostElement: ElementRef;

  constructor(el: ElementRef, private tealium: Tealium) {
    this.hostElement = el;
  }

  @HostListener('focus', ['$event.target.value'])
  public onFocus(): void {
    const tealiumData: TealiumData = {
      event_category: this.tealiumCategory,
      event_action: TealiumEvent.Click,
      event_name: this.hostElement.nativeElement.id,
    };
    this.tealium.link(tealiumData);
  }
}
