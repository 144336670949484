import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { SessionStorageService } from '@app/acquisition/services/session-storage-service/session-storage.service';
import { Environment } from '@app/environment/environment.module';
import { ApplicationDataService } from '@application/application.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SessionStorageObjects } from '../session-storage-service/session-storage.service';

@Injectable()
export class AppInsightService {
  public get appInsightsId(): string {
    return this.appInsights.context.user.accountId;
  }

  private get resolveAppInsightsId(): string {
    const tealiumVars = this.sessionStorage.getSessionStorageObject(
      SessionStorageObjects.tealiumVariables
    );

    // retrieve from session
    if (tealiumVars && tealiumVars.visitorid) {
      return tealiumVars.visitorid;
    }

    // create
    return `${this.s4() + this.s4()}-${this.s4()}-${this.s4() + this.s4()}`;
  }
  public routerSubscription: Subscription;
  public private;
  private appInsights: ApplicationInsights;

  constructor(
    private environment: Environment,
    private router: Router,
    private sessionStorage: SessionStorageService,
    private applicationDataService: ApplicationDataService
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.environment.appInsights.instrumentationKey,
        accountId: this.resolveAppInsightsId,
      },
    });

    this.appInsights.loadAppInsights();

    this.routerSubscription = this.router.events
      .pipe(filter((event: any) => event instanceof ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.logPageView(event.urlAfterRedirects, event.urlAfterRedirects);
        }
      });
  }

  public logPageView(name?: string, uri?: string): void {
    const application = this.applicationDataService.getApplication();

    this.appInsights.trackPageView({
      name,
      uri,
      properties: {
        sequenceId: application?.sequenceApplicationId,
        applicationId: application?.id,
        email: application?.form?.applicant?.emails[0]?.address,
      },
    });
  }

  public trackEvent(name: string, properties?: any): void {
    const application = this.applicationDataService.getApplication();

    this.appInsights.trackEvent({
      name,
      properties: {
        ...properties,
        sequenceId: application?.sequenceApplicationId,
        applicationId: application?.id,
        email: application?.form?.applicant?.emails[0]?.address,
      },
    });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
}
