import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { Environment } from '@app/environment/environment.module';
import { ScriptLoader } from '@app/script-loader.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

declare let nid: Function;

@Injectable()
export class NeuroIdService {
  public routerSubscription: Subscription;
  public neuroId: string;

  constructor(
    private scriptLoader: ScriptLoader,
    private environment: Environment,
    private router: Router,
    private ngZone: NgZone
  ) {}

  public init(): void {
    this.scriptLoader.loadScript(this.environment.nueroIdScriptUrl).then(() => {
      this.routerSubscription = this.router.events
        .pipe(filter((event: any) => event instanceof ResolveEnd))
        .subscribe((event: ResolveEnd) => {
          const activatedComponent = this.getActivatedComponent(
            event.state.root
          );
          if (activatedComponent) {
            this.ngZone.runOutsideAngular(() => {
              nid('start');
            });
          }
        });
    });
  }

  public sendUserId(userID: string): void {
    nid('setUserId', userID);
  }

  public setCheckpoint(name: string): void {
    nid('setCheckpoint', name);
  }

  public setVariable(type: string, name: string): void {
    nid('setVariable', type, name);
  }

  public getNeuroSessionId(): string {
    const neuroIdservice = this;
    nid(function (api: any): void {
      const nidClientId = api.getIdentity();
      neuroIdservice.neuroId = nidClientId;
    }, neuroIdservice);
    return neuroIdservice.neuroId;
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }
}
