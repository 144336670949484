import { Injectable } from '@angular/core';
import {
  SessionStorageKeys,
  SessionStorageObjects,
  SessionStorageService,
} from '@app/acquisition/services/session-storage-service/session-storage.service';
import { UserInactivityStatusService } from '@app/acquisition/shared-module/inactivity-module/user-inactivity.service';
import {
  Environment,
  PlaidLinkItem,
} from '@app/environment/environment.module';
import { Tealium } from '@app/tealium/tealium.service';
import { AsyncSubject, Observable, Subject, Subscription } from 'rxjs';
import { ApplicationData } from '../application/application.models';
import { ApplicationDataService } from '../application/application.service';
import { BankConnectionApi } from '../apply/connect-bank/bank-connection-api/bank-connection-api.service';
import { AppInsightService } from '../services/app-insights/app-insights.service';
import { PlaidLinkApiService } from './plaid-link-api.service';
import {
  PlaidError,
  PlaidErrorCodes,
  PlaidEventMetaData,
  PlaidEventName,
  PlaidEventRequest,
  PlaidExitMetaData,
  PlaidObj,
  PlaidStatic,
  PlaidSuccessMetaData,
  PlaidSuccessObject,
} from './plaid-link.types';

@Injectable()
export class PlaidLinkService {
  public plaidOAuthStateId: string;
  private plaid: PlaidObj;
  private plaidSubject = new AsyncSubject<PlaidObj>();
  private plaidStatic: PlaidStatic = window['Plaid'];
  private successSubject = new Subject<PlaidSuccessObject>();
  private application: ApplicationData;
  private excludedErrorCodes = [
    PlaidErrorCodes.INSUFFICIENT_CREDENTIALS,
    PlaidErrorCodes.INVALID_CREDENTIALS,
    PlaidErrorCodes.INVALID_MFA,
  ];
  private idleTimeOutSubscription: Subscription;

  constructor(
    private environment: Environment,
    private appInsights: AppInsightService,
    private applicationDataService: ApplicationDataService,
    private inactivityService: UserInactivityStatusService,
    private plaidLinkApiService: PlaidLinkApiService,
    private bankConnectionApi: BankConnectionApi,
    private tealium: Tealium,
    private sessionStorageService: SessionStorageService
  ) {}

  public init(): void {
    this.application = this.applicationDataService.getApplication();
    this.plaidOAuthStateId = this.getPlaidOauthStateId();
    if (this.plaidOAuthStateId) {
      this.reInitializePlaidLink();
    } else {
      this.createPlaidLink();
    }
  }

  public getPlaidOauthStateId(): string {
    return this.sessionStorageService.getSessionStorageItem(
      SessionStorageKeys.oauthStateId
    );
  }

  public openPlaidLink(): void {
    this.plaidSubject.asObservable().subscribe((plaid: PlaidObj) => {
      plaid.open();
      this.listenForIdleTimeout();
      this.setLogRocketPrivateAttribute();
    });
  }

  public listenForIdleTimeout(): void {
    this.idleTimeOutSubscription =
      this.inactivityService.idleTimeOutStatusObservable.subscribe(() => {
        this.plaid.exit({ force: true });
      });
  }

  public setLogRocketPrivateAttribute(): void {
    document.querySelectorAll('iframe').forEach((iframe: HTMLIFrameElement) => {
      if (iframe.id?.startsWith('plaid-link-iframe-')) {
        const privateAttribute = document.createAttribute('data-private');
        iframe.setAttributeNode(privateAttribute);
      }
    });
  }

  public getSuccessMessage(): Observable<PlaidSuccessObject> {
    return this.successSubject.asObservable();
  }

  public closePlaid(): void {
    if (this.plaid) {
      this.plaid.exit({ force: true });
      this.destroyPlaidLink();
    }
  }

  public reInitializePlaidLink(): void {
    if (this.plaid) {
      this.destroyPlaidLink();
    }
    const lenderCode = this.applicationDataService.lenderCode;
    const plaidLink: PlaidLinkItem = this.environment.plaidLink[lenderCode];
    const plaidLinkToken = this.sessionStorageService.getSessionStorageItem(
      SessionStorageKeys.plaidLinkToken
    );

    this.plaid = this.plaidStatic.create({
      env: plaidLink.environment,
      token: plaidLinkToken,
      receivedRedirectUri: `${location.href}?oauth_state_id=${this.plaidOAuthStateId}`,
      onSuccess: this.onPlaidSuccess.bind(this),
      onEvent: this.trackEvents.bind(this),
      onExit: (error: PlaidError | null, metadata: PlaidExitMetaData) =>
        this.onPlaidExit(error, metadata),
    });
    this.plaid.open();
    this.listenForIdleTimeout();
    this.setLogRocketPrivateAttribute();
  }

  public createPlaidLink(): void {
    const { products } = this.environment.plaidLink;
    const lenderCode = this.applicationDataService.lenderCode;
    const plaidLink: PlaidLinkItem = this.environment.plaidLink[lenderCode];

    this.plaidLinkApiService
      .getLinkToken(
        lenderCode,
        plaidLink.clientName,
        products,
        plaidLink.linkCustomizationName,
        ''
      )
      .subscribe((linkToken: string) => {
        this.sessionStorageService.setSessionStorageItem(
          SessionStorageKeys.plaidLinkToken,
          linkToken
        );

        if (this.plaid) {
          this.destroyPlaidLink();
        }

        this.plaid = this.plaidStatic.create({
          token: linkToken,
          env: plaidLink.environment,
          onSuccess: this.onPlaidSuccess.bind(this),
          onEvent: this.trackEvents.bind(this),
          onExit: (error: PlaidError | null, metadata: PlaidExitMetaData) =>
            this.onPlaidExit(error, metadata),
        });
        this.plaidSubject.next(this.plaid);
        this.plaidSubject.complete();
      });
  }

  private trackEvents(eventName: string, metaData: PlaidEventMetaData): any {
    const isCodeExcluded = this.excludedErrorCodes.includes(
      metaData.error_code
    );

    metaData.amsAppId = this.application.id;
    metaData.appSequenceId = this.application.sequenceApplicationId;
    this.appInsights.trackEvent(eventName, metaData);
    this.bankConnectionApi
      .postPlaidEvent(this.createPlaidEventRequest(eventName, metaData))
      .subscribe();

    if (eventName !== PlaidEventName.SEARCH_INSTITUTION) {
      this.tealium.trackPlaidEvent(`plaid_${eventName}`);
    }

    if (eventName === 'ERROR' && !isCodeExcluded) {
      this.bankConnectionApi
        .postPlaidErrorCode({
          code: metaData.error_code,
        })
        .subscribe(
          () => {},
          () => {}
        );
    }

    this.inactivityService.pingInterruptionToIdle();
  }

  private createPlaidEventRequest(
    eventName: string,
    metaData: PlaidEventMetaData
  ): PlaidEventRequest {
    const plaidEvent: PlaidEventRequest = new PlaidEventRequest();
    plaidEvent.eventType = eventName;
    plaidEvent.timeStamp = metaData.timestamp;
    plaidEvent.data = metaData;

    return plaidEvent;
  }

  /* istanbul ignore next */
  private onPlaidSuccess(
    publicToken: string,
    metaData: PlaidSuccessMetaData
  ): void {
    this.sessionStorageService.deleteSessionStorageItem(
      SessionStorageKeys.plaidLinkToken
    );
    this.successSubject.next({
      publicToken: publicToken,
      metaData: metaData,
    });
  }

  private onPlaidExit(
    error: PlaidError | null,
    metaData: PlaidExitMetaData
  ): void {
    // destroy and re-create the Plaid Handler
    this.plaidOAuthStateId = null;
    this.sessionStorageService.deleteSessionStorageItem(
      SessionStorageKeys.oauthStateId
    );
    this.createPlaidLink();
  }

  private destroyPlaidLink(): void {
    this.plaidSubject = new AsyncSubject<PlaidObj>();
    this.plaid.destroy();
    if (this.idleTimeOutSubscription && !this.idleTimeOutSubscription.closed) {
      this.idleTimeOutSubscription.unsubscribe();
    }
  }
}
