import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@app/environment/environment.module';
import { Observable } from 'rxjs';
export enum disclosureTypes {
  KnowBeforeYouBorrow = 'KnowBeforeYouBorrow',
  CreditScoreDisclosure = 'CreditScoreDisclosure',
  CreditServiceContract = 'CreditServiceContract',
  CommunicationsAgreement = 'CommunicationsAgreement',
  ConsenttoNDMoneyBroker = 'ConsentToNDMoneyBroker',
  PatriotAct = 'PatriotAct',
  CreditReportAuthFormerRefi = 'CreditReportAuthorizationFormerRefinance',
}

export interface Disclosure {
  name: string;
  html: string;
}

export interface DisclosureResponse {
  disclosures: Disclosure[];
}

@Injectable()
export class DisclosuresRetrievalService {
  constructor(private http: HttpClient, private env: Environment) {}

  public getDisclosures(
    ...disclosures: disclosureTypes[]
  ): Observable<DisclosureResponse> {
    const queryParams = [];

    disclosures.forEach((disclosure: disclosureTypes) => {
      queryParams.push(`name=${disclosure}`);
    });

    return this.http.get(
      `${this.env.brandApi.url}/api/v1/disclosures?${queryParams.join('&')}`
    ) as Observable<DisclosureResponse>;
  }

  public getAcceptanceDisclosures(): Observable<DisclosureResponse> {
    return this.http.get(
      `${this.env.brandApi.url}/api/v1/accept-disclosures`
    ) as Observable<DisclosureResponse>;
  }

  public getPatriotActDisclosure(stateCode: string): Observable<Disclosure> {
    return this.http.get(
      `${this.env.brandApi.url}/api/v1/patriot-act?brand=RISE&state=${stateCode}`
    ) as Observable<Disclosure>;
  }
}
