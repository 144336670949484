<div mat-dialog-title class="dialog-header">
  <p class="modal-title-text">Hey there,</p>
</div>
<mat-dialog-content class="mat-typography">
  <div>
    <p>
      Redirecting you to a better experience ... 
    </p>
  </div>
</mat-dialog-content>
