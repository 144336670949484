import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppInsightService } from '@app/acquisition/services/app-insights/app-insights.service';
import { Environment } from '@app/environment/environment.module';
import { LocationProxy } from '@app/location-proxy/location-proxy.service';
import { GettingStarted } from '../form/getting-started.form';

@Component({
  selector: 'app-redirect-to-bp-modal',
  templateUrl: './redirect-to-bp-modal.component.html',
  styleUrls: ['./redirect-to-bp-modal.component.scss']
})
export class RedirectToBpModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public applicant: GettingStarted,
    public dialogRef: MatDialogRef<RedirectToBpModalComponent>,
    private locationProxy: LocationProxy,
    private environment: Environment,
    private appInsights: AppInsightService
  ) { }

  ngOnInit(): void {
    this.appInsights.trackEvent('Redirect-To-Blueprint', {
      user: this.applicant?.email,
    });

    const appObject : any = {
      firstname: this.applicant?.firstName,
      lastname: this.applicant?.lastName,
      email: this.applicant?.email,
      state: this.applicant?.state,
      postalCode: this.applicant?.zipCode,
      mobile: this.applicant?.mobilePhoneNumber,
      code: this.applicant?.directMailCode,
      abTesting: this.applicant?.abTesting
    }

    const encodedString = this.encodeObject(appObject);

    this.dialogRef?.afterOpened()?.subscribe(_ => {
      setTimeout(() => {
        this.dialogRef.close();
        this.locationProxy?.assign(`${this.environment?.url?.blueprint}?redirect=${encodedString}`);
      }, 5000);
    });
    
  }

  private encodeObject(jsonObject) {
    const jsonString = JSON.stringify(jsonObject);
    const btoaVal = btoa(jsonString);
    return encodeURIComponent(btoaVal);
  }

}
