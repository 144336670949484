import { NgModule } from '@angular/core';
// import { AppConfiguration } from '@app/appconfig/appconfig.configuration';
import { ReCaptchaConfiguration } from '@recaptcha/recaptcha.configuration';
import { SmartyStreetConfiguration } from '@smarty-street/smarty-street.configuration';
import { TealiumConfiguration } from '@tealium/tealium.configuration';

export interface PlaidLinkItem {
  environment: string;
  linkCustomizationName?: string;
  clientName?: string;
}

export class Environment {
  public readonly productId: number;
  public readonly environmentName: string;
  public readonly productionMode: boolean;
  public readonly url: {
    readonly home: string;
    readonly api: string;
    readonly wiq: string;
    readonly blueprint: string;
    readonly declinedRedirect: string;
  };
  public readonly smartyStreets: SmartyStreetConfiguration;
  public readonly tealium: TealiumConfiguration;
  public readonly reCaptcha: ReCaptchaConfiguration;
  public readonly brandApi: {
    url: string;
    underwriteRetry: {
      retryCount: number;
      retryTimeout: number;
    };
    submitApplicationRetry: {
      retryCount: number;
      retryTimeout: number;
    };
  };
  // public readonly appConfig: AppConfiguration;

  public readonly plaidLink: {
    readonly products: string[];
    readonly ELVT: PlaidLinkItem;
    readonly CCB: PlaidLinkItem;
    readonly FNWS: PlaidLinkItem;
  };
  public readonly butterCms: {
    readonly token: string;
    readonly url: string;
    readonly numRetries: number;
    readonly retryTimeout: number;
  };
  public readonly acquireKey: string;
  public readonly appInsights: {
    readonly instrumentationKey: string;
  };
  public readonly logRocket: {
    readonly applicationId: string;
  };
  public readonly inactivity: {
    readonly idle: number;
    readonly timeout: number;
  };
  public readonly nueroIdScriptUrl: string;
  public readonly httpRetry: {
    readonly retryCount: number;
    readonly retryTimeout: number;
  };
  public readonly gpc: {
    readonly apiVersion: string;
    readonly environment: string;
    readonly apiManagerSubscriptionKey: string;
    readonly postUrl: string;
  };
  public readonly hideMonetizationStates: string;
  public readonly pendingUnderwritingTimeoutInMinutes: number;

  public readonly coBrowseConfiguration?: {
    orgGuid: string;
    chatDeploymentKey: string;
    chatScriptUrl: string;
    chatRegion: string;
    webchatAppUrl: string;
    webchatServiceUrl: string;
    promptForSecurityKey: boolean;
    cobrowseScriptUrl: string;
    cobrowseDeploymentKey: string;
    logLevel: string;
    linkText: string;
    linkBackground: string;
    stylesUrl: string;
    appBaseUrl: string;
    PhoneNumber: string;
  };
}

@NgModule({
  providers: [
    {
      provide: Environment,
      useFactory: () => window['environment'],
    },
  ],
})
export class EnvironmentModule {}
